<template>
  <div class="container">
    <div class="jumbotron" style="margin-top">
      <h1 class="display-4" style="font-weight: 500">
        Paragliding Colorado's Western Slope
      </h1>
      <hr class="my-4" />
      <carousel-component></carousel-component>
      <div class="card" style="margin-top:20px;">
        <div class="card-body">
          <h5 class="card-title">Sites</h5>
          <div class="card-text">
            <div v-for="site in sites" :key="site.route">
              <router-link class="dropdown-item" :to="{ path: site.route }">
                {{ site.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import CarouselComponent from '@/components/Carousel.vue';
import data from '../data/data.json';

export default {
  name: 'HomeView',
  components: {
    CarouselComponent,
  },
  data() {
    return {
      sites: data.sites,
    };
  },
};
</script>

<style scoped>
.jumbotron {
  margin-top: 20px;
  text-align: center;
}
</style>
