import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import data from '../data/data.json';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/weather',
    name: 'weather',
    component: () => import('../views/WeatherView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

// build route for each flying site
data.sites.forEach((site) => {
  routes.push(
    {
      path: site.route,
      component: () => import(/* webpackChunkName: "about" */ '../components/SiteComponent.vue'),
      props: { name: site.name, id: site.id },
    },
  );
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
