<template>
  <nav>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/"
          >Western Slope Soaring</router-link
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Flying Sites
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li v-for="site in sites" :key="site.route">
                  <router-link class="dropdown-item" :to="{ path: site.route }">
                    {{ site.name }}
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/weather">Weather</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">About</router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </nav>
</template>

<script>
import data from '../data/data.json';

export default {
  data() {
    return {
      sites: data.sites,
    };
  },
};
</script>
